const attributesToQueryParams = {
  'data-id': 'slug',
  'data-count': 'count',
  'data-columns': 'columns',
};

const queryParamBuilder = (container) => {
  if (container) {
    const attributes = [...container.attributes];
    const result = {};
    attributes.forEach((attribute) => {
      const value = attributesToQueryParams[attribute.name];
      if (value) {
        result[value] = attribute.value;
      }
    });
    return result;
  }
};

const srcBuilder = (url, params) => {
  if (params) {
    const paramsToURL = Object.entries(params).reduce((acc, [key, value]) => {
      if (value) {
        return `${acc}${key}=${value}&`;
      }
      return `${acc}`;
    }, '');
    return url + '?' + paramsToURL;
  } else {
    return url;
  }
};

const onReady = () => {
  var themeContainer = document.querySelector('.theme');
  let popupIframe;

  const ACTIONS = {
    openPublicPost: 'OPEN_PUBLIC_POST',
    openPopup: 'OPEN_POPUP',
    popupLoaded: 'POPUP_LOADED',
    publicPostSubmitted: 'PUBLIC_POST_SUBMITTED',
    closePopup: 'CLOSE_POPUP',
    changePostPopup: 'CHANGE_POST_POPUP',
    expandPostMessage: 'EXPAND_POST_MESSAGE',
    resizeWindow: 'RESIZE_WINDOW',
  };

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function receiveMessage(event) {
    let data = {};
    if (isJsonString(event.data)) {
      data = JSON.parse(event.data);
    }

    if (data.type && typeof data === 'object') {
      switch (data.type) {
        case ACTIONS.openPopup:
          popupIframe.style.display = 'block';
          popupIframe.focus();
          popupSelectorIframe?.contentWindow?.postMessage({ ...data }, ORIGIN);
          break;

        case ACTIONS.openPublicPost:
          popupIframe.style.display = 'block';
          popupSelectorIframe?.contentWindow?.postMessage({ ...data }, ORIGIN);
          break;
        case ACTIONS.publicPostSubmitted:
          themeSelectorIframe && themeSelectorIframe.contentWindow.postMessage({ ...data }, ORIGIN);
          break;
        case ACTIONS.popupLoaded:
          themeSelectorIframe && themeSelectorIframe.contentWindow.postMessage({ ...data }, ORIGIN);
          break;
        case ACTIONS.closePopup:
          popupIframe.style.display = 'none';
          themeSelectorIframe.focus();
          // Overriden by focus event on theme iframe.
          // themeSelectorIframe?.contentWindow?.postMessage({ ...data }, ORIGIN);
          break;

        case ACTIONS.changePostPopup:
          themeSelectorIframe && themeSelectorIframe.contentWindow.postMessage({ ...data }, ORIGIN);
          break;

        case ACTIONS.expandPostMessage:
          themeContainer.style.height = data.containerSize + 'px';
          break;

        case ACTIONS.resizeWindow:
          if (document.getElementById('theme-page')) {
            themeContainer.style.height = `${parseInt(data.height) + 60}px`;
            document.getElementById('theme-page').style.height = '100%';
          }
          break;
        default:
          break;
      }
    }
  }

  const createPopup = () => {
    popupIframe = document.createElement('iframe');
    popupIframe.setAttribute('src', iframePopupSource);
    popupIframe.setAttribute('id', 'theme-popup');
    popupIframe.setAttribute('allow', 'fullscreen');
    popupIframe.setAttribute('crossorigin', 'anonymous');
    popupIframe.setAttribute('title', 'Post Frame');

    popupIframe.style.display = 'none';
    popupIframe.style.position = 'fixed';
    popupIframe.style.width = '100%';
    popupIframe.style.height = '100%';
    popupIframe.style.left = '0px';
    popupIframe.style.right = '0px';
    popupIframe.style.top = '0px';
    popupIframe.style.bottom = '0px';
    popupIframe.style.border = 'none';
    popupIframe.style.zIndex = '9999999';
    document.body.appendChild(popupIframe);
  };

  window.addEventListener('message', receiveMessage, false);

  const baseUrl = THEME_URL;

  const queryParams = queryParamBuilder(themeContainer);
  const src = srcBuilder(baseUrl, queryParams);

  const themePagesIframe = document.createElement('iframe');
  themePagesIframe.setAttribute('src', baseUrl);
  themePagesIframe.setAttribute('src', src);
  themePagesIframe.setAttribute('id', 'theme-page');
  themePagesIframe.setAttribute('title', 'תוכן רשתות חברתיות');

  themePagesIframe.style.height = themeContainer && themeContainer.style.height;
  themePagesIframe.style.width = themeContainer ? themeContainer.style.width : '100%';

  themePagesIframe.style.border = 'none';
  themePagesIframe.setAttribute('allow', 'fullscreen');
  themePagesIframe.setAttribute('crossorigin', 'anonymous');
  themeContainer && themeContainer.appendChild(themePagesIframe);

  const iframePopupSource = POPUP_URL;
  if (!document.querySelector('#theme-popup')) {
    createPopup();
  }

  const popupSelectorIframe = document.querySelector('#theme-popup');
  const themeSelectorIframe = document.querySelector('#theme-page');
};

if (document.readyState === 'interactive' || document.readyState === 'complete') {
  onReady();
} else {
  document.addEventListener('DOMContentLoaded', onReady);
}

// Remove polyfill
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      },
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
